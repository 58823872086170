import { createAsyncThunk } from "@reduxjs/toolkit";
import { poster } from "../../services";
import { login } from "../../services/requests";

export const loginRequest = createAsyncThunk<
  any,
  { phoneNumber: string; password: string }
>("auth/login", async (payload, { rejectWithValue, fulfillWithValue }) => {
  try {
    const { data } = await poster(login, payload, {
      baseURL: process.env.REACT_APP_CORE_ENDPOINT,
    });
    return fulfillWithValue(data);
  } catch (error: any) {
    return rejectWithValue(error.response.status);
  }
});
