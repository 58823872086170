import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { routingSlice } from "./slices/routing-slice";
import { Reducer } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistStore,
  persistReducer,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { customerVisits, storeInfo } from "./apis";
import { authSlice } from "./slices/auth-slice";
import { assignedBranchesSlice } from "./slices/assigned-branches-slice";

const persistAuth = {
  key: "auth",
  storage,
  blacklist: ["status"],
};

const persistConfig = {
  key: "root",
  storage,
  whitelist: [],
};
const persistRouting = {
  key: "routing",
  storage,
};

const reducers = {
  authReducer: persistReducer(persistAuth, authSlice.reducer),
  routingReducer: persistReducer(persistRouting, routingSlice.reducer),
  assignedBranchesReducer: assignedBranchesSlice.reducer,
  [customerVisits.reducerPath]: customerVisits.reducer,
  [storeInfo.reducerPath]: storeInfo.reducer,
};

const combinedReducer = combineReducers<typeof reducers>(reducers);

export const rootReducer: Reducer<RootState> = (state, action) => {
  return combinedReducer(state, action);
};

const persistReducers = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([customerVisits.middleware, storeInfo.middleware]),
});
export const persistor = persistStore(store);

export type RootState = ReturnType<typeof combinedReducer>;