import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, storeURL } from "../../services";
import { IStore } from "../../types";

export const storeInfo = createApi({
  reducerPath: "storeInfoReducer",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_CORE_ENDPOINT as string,
  }),
  tagTypes: ["store"],
  endpoints: (builder) => ({
    getStoreInfo: builder.query<IStore, void>({
      query: () => ({ url: storeURL, method: "get" }),
    }),
  }),
});

export const { useGetStoreInfoQuery } = storeInfo;
