import { createSlice } from "@reduxjs/toolkit";
import { loginRequest } from "../thunks";
import { LoginState } from "../../types";

const initialState: LoginState = {
  token: "",
  cashier_id: "",
  isLoggedIn: false,
  status: "idle",
};

export const authSlice = createSlice({
  initialState,
  name: "authSlice",
  reducers: {
    logout: (state) => {
      return {
        ...state,
        isLoggedIn: false,
        token: "",
        status: "idle",
      };
    },
  },
  extraReducers(builder) {
    builder.addCase(loginRequest.pending, (state) => ({
      ...state,
      status: "loading",
    })),
      builder.addCase(loginRequest.fulfilled, (state, { payload }) => {
        return {
          ...state,
          isLoggedIn: true,
          token: payload.token,
          cashier_id: payload.id,
          status: "succeeded",
        };
      }),
      builder.addCase(loginRequest.rejected, (state) => {
        return {
          ...state,
          isLoggedIn: false,
          status: "failed",
        };
      });
  },
});

export const { logout } = authSlice.actions;
export default authSlice.reducer;
