import { getAssignedBranches } from "./../thunks/assigned-branches";
import { createSlice } from "@reduxjs/toolkit";
import { AssignedBranchesSliceInitialState, LoadingStatus } from "../../types";

const initialState: AssignedBranchesSliceInitialState = {
  branches: [],
  loadingState: LoadingStatus.idle,
};

export const assignedBranchesSlice = createSlice({
  initialState,
  name: "assignedBranches",
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAssignedBranches.pending, (state) => {
      return {
        ...state,
        loadingState: LoadingStatus.loading,
      };
    }),
      builder.addCase(getAssignedBranches.fulfilled, (state, { payload }) => {
        return {
          ...state,
          branches: payload.branches,
          loadingState: LoadingStatus.success,
        };
      }),
      builder.addCase(getAssignedBranches.rejected, (state) => {
        return {
          ...state,
          loadingState: LoadingStatus.failed,
        };
      });
  },
});

export default assignedBranchesSlice.reducer;
