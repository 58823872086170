import React from "react";
import { Navigate } from "react-router-dom";
import { useSelect } from "../../hooks";
const NotFound: React.FC = () => {
  const {
    authReducer: { isLoggedIn },
    routingReducer: { selectedBranchId }
  } = useSelect((state) => state);

  return  <Navigate to={isLoggedIn ? selectedBranchId ? `/branches/${selectedBranchId}` : "/branches" : "/login"} />;
};

export default NotFound;
