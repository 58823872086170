import { createSlice } from "@reduxjs/toolkit";
import { RoutingSlicePayload } from "../../types";

const initialState: RoutingSlicePayload = {
  from: "",
  to: "",
  selectedBranchId: undefined,
};

export const routingSlice = createSlice({
  name: "routing",
  initialState,
  reducers: {
    selectedBranch: (state, { payload }) => {
      return {
        ...state,
        selectedBranchId: payload,
      };
    },
    setRoutes: (state, { payload: { from, to } }) => {
      state.from = from;
      state.to = to;
    },
    resetRoutes: (state) => {
      state.from = "";
      state.to = "";
    },
  },
});

export const { setRoutes, resetRoutes, selectedBranch } = routingSlice.actions;
export default routingSlice.reducer;
