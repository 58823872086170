import React, { useEffect } from "react";
import { AppRoutes } from "./routes/AppRoutes";
import styles from "./styles.module.sass";
import "./App.scss";
import { Toaster } from "react-hot-toast";
import { storeInfo } from "./store/apis";
import { useSelect } from "./hooks";

const App: React.FC = () => {
  
  const { storeInfoReducer } = useSelect((state) => state);
  const { data } = storeInfo.endpoints.getStoreInfo.select()({
    storeInfoReducer,
  });

  useEffect(() => {
    document
      .getElementsByTagName("html")[0]
      .setAttribute("style", `background: ${data?.background_color ?? "#fff"}`);
  }, [data?.background_color]);

  return (
    <div
      className={styles.App}
      style={{ backgroundColor: data?.background_color }}
    >
      <Toaster />
      <AppRoutes />
    </div>
  );
};

export default App;
