import React, { useEffect, useMemo } from "react";
import { useSelect } from "../hooks";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setRoutes } from "../store/slices/routing-slice";

const ProtectedRoute: React.FC = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const {
    authReducer: { isLoggedIn },
    routingReducer: routingState
  } = useSelect((state) => state);

  useEffect(() => {
    dispatch(
      setRoutes({...routingState, from: pathname, to: isLoggedIn ? pathname : "/login" })
    );
  }, [pathname, isLoggedIn, routingState]);
  
  const path = useMemo(() => {
    return isLoggedIn ? <Outlet /> : <Navigate to="/login" />;
  }, [pathname, isLoggedIn]);

  return <>{path}</>;
};

export default ProtectedRoute;
