import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import NotFound from "../components/NotFound";
import ProtectedRoute from "./ProtectedRoute";

const CustomerPhoneNumber = React.lazy(
  () => import("../pages/CustomerPhoneNumber")
);
const CustomerVisits = React.lazy(() => import("../pages/CustomerVisits"));
const Home = React.lazy(() => import("../pages/Home"));
const Login = React.lazy(() => import("../pages/Login"));

export const AppRoutes: React.FC = () => {
  return (
    <React.Suspense
      fallback={
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ClipLoader size={50} color="#EF9341" />
        </div>
      }
    >
      <Router>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route path="/:branch_id/visits/:customer_id" element={<CustomerVisits />} />
            <Route
              path="/branches/:branch_id"
              element={<CustomerPhoneNumber />}
            />
            <Route path="/branches" element={<Home />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </React.Suspense>
  );
};

export default AppRoutes;
