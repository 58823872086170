import { getter } from './../../services';
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getBranchesList as url } from "../../services/requests";

export const getAssignedBranches = createAsyncThunk<any>(
  "get/assigned-branches",

  async (_, { rejectWithValue, fulfillWithValue }) => {
    try {
      const { data } = await getter(url, {
        baseURL: process.env.REACT_APP_CORE_ENDPOINT,
      });
      return fulfillWithValue(data);
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  });
