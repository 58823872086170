import { store } from "../store";
export interface FormValues {
  phoneNumber: string;
  password: string;
  branch: string;
}
export interface LoginState {
  token: string;
  cashier_id: string,
  isLoggedIn: boolean;
  status: "idle" | "succeeded" | "failed" | "loading";
}

export enum LoadingStatus {
  idle = "idle",
  success = "success",
  failed = "failed",
  loading = "loading",
}

export interface RoutingSlicePayload {
  from: string;
  to: string;
  selectedBranchId?: string
}
export interface Settings {
  delivery_management_status: string;
  delivery_method: string;
  delivery_status: string;
  pickup_status: string;
}
export interface BranchInfo {
  address: string;
  app_version: string;
  ar_detailed_address: string;
  ar_name: string;
  created_at: number | null;
  detailed_address: string;
  en_detailed_address: string;
  en_name: string;
  id: string;
  lat: number | null;
  lng: number | null;
  name: string;
  store_id: string;
  type: number | null;
  updated_at: number | null;
  numbers: number[];
  settings: Settings;
}

export interface AssignedBranchesSliceInitialState {
  branches: BranchInfo[];
  loadingState: LoadingStatus;
}
export type AppDispatch = typeof store.dispatch;

export interface IOfflineVisitResponse {
  customer: ICustomer;
  visits: number;
  points: number;
  gifts: IGift[];
}

export interface ICustomer {
  id: string;
  phone_number: string;
  country_code: string;
}

export interface IGift {
  id: string;
  price: number;
  name: string;
  image: string;
  remainder_visits_to_unlock: number;
}

export interface IStore {
  total_base: number;
  allow_reach: number;
  operating_start_date: number;
  allow_leaderboard: number;
  activation_date: number;
  twitter_link: string;
  timezone: string;
  facebook_link: string;
  allow_receipt_code: number;
  created_at: number;
  bio: string;
  pickup_status: string;
  hide_items: number;
  last_7_days_visits: number;
  updated_at: number;
  translated_name: string;
  payment_notification: number;
  expiration_period: number;
  reach_points: number;
  enable_pickup_time_confirmation: boolean;
  en_name: string;
  last_3_days_visits: number;
  cover_image: string;
  id: string;
  app_users_count: number;
  payment_date: number;
  store_image: string;
  loyalty_status: string;
  is_follow: number;
  payment_term: number;
  payment_status: number;
  ar_name: string;
  converting_ratio: number;
  temp_customers_count: number;
  country_code: string;
  active_leaderboard: number;
  country_iso_code: string;
  background_color: string;
  live_updates_filled: number;
  api_key: string;
  followers_count: number;
  name: string;
  location: string;
  app_base: number;
  status: number;
}
