import { createApi } from "@reduxjs/toolkit/query/react";
import { axiosBaseQuery, customerVisitsURL, sendFixedOfflinePoints } from "../../services";
import { IOfflineVisitResponse } from "../../types";

export const customerVisits = createApi({
  reducerPath: "customervisitsReducer",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_CORE_ENDPOINT as string,
  }),
  endpoints: (builder) => ({
    addFixedOfflinePoints: builder.mutation<
      { code?: string, customer_id?: string,  message: string },
      { branch_id: string; phone_number: string; cashier_id: string }
    >({
      query: (data) => ({
        url: sendFixedOfflinePoints,
        method: "post",
        data: {
          ...data,
        },
      }),
    }),
    getCustomerVisits: builder.mutation<IOfflineVisitResponse, string>({
      query: (customer_id) => ({
        url: customerVisitsURL(customer_id),
        method: "get",
      }),
    }),
  }),
});

export const { useGetCustomerVisitsMutation, useAddFixedOfflinePointsMutation } =
  customerVisits;
